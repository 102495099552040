import React from "react";
import { FaHtml5, FaReact, FaNode, FaDocker, FaPython } from "react-icons/fa";
import {
  SiTailwindcss,
  SiExpress,
  SiMongodb,
  SiTypescript,
  SiMysql,
} from "react-icons/si";

function TechStack() {
  const techStack = [
    {
      name: "HTML",
      icon: <FaHtml5 color="#f06529" size={40} />,
      bgColor: "bg-white",
    },
    {
      name: "Tailwind",
      icon: <SiTailwindcss color="#06b6d4" size={40} />,
      bgColor: "bg-white",
    },
    {
      name: "React",
      icon: <FaReact color="#61dbfb" size={40} />,
      bgColor: "bg-white",
    },
    {
      name: "Express",
      icon: <SiExpress color="#000000" size={40} />,
      bgColor: "bg-white",
    },
    {
      name: "Node",
      icon: <FaNode color="#3c873a" size={40} />,
      bgColor: "bg-white",
    },
    {
      name: "MongoDB",
      icon: <SiMongodb color="#47A248" size={40} />,
      bgColor: "bg-white",
    },
    {
      name: "Docker",
      icon: <FaDocker color="#2496ed" size={40} />,
      bgColor: "bg-white",
    },
    {
      name: "TypeScript",
      icon: <SiTypescript color="#007acc" size={40} />,
      bgColor: "bg-white",
    },
    {
      name: "Python",
      icon: <FaPython color="#306998" size={40} />,
      bgColor: "bg-white",
    },
    {
      name: "MySQL",
      icon: <SiMysql color="#00758f" size={40} />,
      bgColor: "bg-white",
    },
  ];

  return (
    <div className="text-center mt-12 mb-12">
      <h1 className="font-serif text-4xl text-slate-600 mb-8 uppercase">
        Tech Stack
      </h1>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 px-6">
        {techStack.map((tech, index) => (
          <div key={index} className="flex flex-col items-center space-y-4">
            <div
              className={`w-20 h-20 flex items-center justify-center rounded-full ${tech.bgColor} shadow-lg`}
            >
              {tech.icon}
            </div>
            <h1 className="text-lg font-serif">{tech.name}</h1>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TechStack;
