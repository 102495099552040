import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function Contact({ isDarkMode }) {
  const [data, setData] = useState({
    name: "",
    lastName: "",
    email: "",
    message: "",
  });

  const changeHandler = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const { name, email, message, lastName } = data;

  const handleClick = async (e) => {
    e.preventDefault();

    try {
      if (!name || !email || !message || !lastName) {
        return toast.error("Please fill all fields");
      }

      const res = await axios.post("/api/email", { name, email, message });

      if (res.data.success) {
        toast.success(res.data.message);
        setData({
          name: "",
          lastName: "",
          email: "",
          message: "",
        });
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="mt-12 mb-12 mx-4 lg:mx-20 shadow-md rounded-md grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="hidden lg:block overflow-hidden rounded-md">
        <img
          className="object-cover rounded-md"
          src="/images/contact.webp"
          alt="contact"
        />
      </div>

      <div className="flex flex-col p-6 mb-8">
        <h1 className="text-3xl md:text-4xl text-slate-600 font-serif uppercase border-b border-gray-300 mb-6">
          Contact
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label className="text-start px-4 py-2 font-serif uppercase">
              First Name
            </label>
            <input
              className={` ${
                isDarkMode ? "bg-[#2e2e2e]" : ""
              } mt-2 text-md font-serif  focus:outline-none border-b-2 focus:border-blue-500 border-slate-900 px-3 py-2`}
              type="text"
              onChange={changeHandler}
              placeholder="Sandy"
              value={data.name}
              name="name"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-start px-4 py-2 font-serif uppercase">
              Last Name
            </label>
            <input
              className={` ${
                isDarkMode ? "bg-[#2e2e2e]" : ""
              } mt-2 text-md font-serif  focus:outline-none border-b-2 focus:border-blue-500 border-slate-900 px-3 py-2`}
              type="text"
              value={data.lastName}
              onChange={changeHandler}
              name="lastName"
              placeholder="Candy"
            />
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <label className="text-start px-4 py-2 font-serif uppercase">
            Email Address
          </label>
          <input
            type="email"
            value={data.email}
            onChange={changeHandler}
            name="email"
            className={`${
              isDarkMode ? "bg-[#2e2e2e]" : ""
            } mt-2 text-md font-serif  focus:outline-none border-b-2 focus:border-blue-500 border-slate-900 px-3 py-2`}
            placeholder="sand@gmail.com"
          />
        </div>

        <div className="flex flex-col mt-6">
          <label className="text-start px-4 py-2 font-serif uppercase">
            Your Message
          </label>
          <textarea
            value={data.message}
            onChange={changeHandler}
            name="message"
            className={`mt-2 text-md font-serif  focus:outline-none border-b-2 focus:border-blue-500 ${
              isDarkMode ? "bg-[#2e2e2e]" : ""
            } border-slate-900 px-3 py-2`}
            placeholder="Write your message here..."
          />
        </div>

        <button
          onClick={handleClick}
          className="mt-6 bg-slate-900 text-white px-8 py-2 rounded-full hover:scale-105 transition-transform duration-300 hover:opacity-90"
        >
          Send
        </button>
      </div>
    </div>
  );
}

export default Contact;
