import React from "react";
import { FaFacebook, FaLinkedin, FaGithub } from "react-icons/fa";

function About() {
  return (
    <div
      id="about"
      className=" mt-12 shadow-md rounded-md gap-6 mx-20 md:grid md:py-0  md:grid-cols-2"
    >
      <div className="overflow-hidden flex flex-col justify-center items-center rounded-md mt-6 ">
        <img
          className="md:ml-12 md:mb-2  object-cover hover:scale-105 duration-1000 rounded-md shadow-lg lg:h-[450px]  "
          src="/images/profile1.jpeg"
          alt="profile"
        />
        <div className="flex justify-center space-x-6 mt-4 mb-4">
          <a
            href="https://www.facebook.com/sandeep.poudel.3517"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800"
          >
            <FaFacebook size={30} />
          </a>
          <a
            href="https://www.linkedin.com/in/sandip-poudel-77a2a829a"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700"
          >
            <FaLinkedin size={30} />
          </a>
          <a
            href="https://www.github.com/Sandip-3"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-800 hover:text-black"
          >
            <FaGithub size={30} />
          </a>
        </div>
      </div>
      <div className=" sm:block lg:block max-h-[140px]flex flex-col mt-6 text-balance overflow-hidden ">
        <h1 className="md:text-4xl text-3xl text-slate-600 font-serif uppercase">
          About Me
        </h1>
        <p className=" hidden lg:block mt-6 md:text-md text-base mx-12 p-2 indent-8 text-justify text-gray-500 font-serif ">
          I am a skilled full-stack developer specializing in JavaScript, with a
          keen interest in data analysis. My expertise spans the development of
          responsive and scalable front-end interfaces using frameworks like
          React, as well as building efficient and robust back-end systems with
          Node.js. Beyond full-stack development, I am passionate about
          uncovering actionable insights through data analysis, leveraging tools
          and techniques to drive informed decision-making. I am dedicated to
          continuous learning and thrive on exploring the dynamic intersections
          of software development and data-driven solutions.
        </p>
        <p className="lg:hidden text-sm mt-6 mx-2 md:text-md mb-6   text-gray-500 font-serif ">
          I am a full-stack developer specializing in JavaScript with expertise
          in React and Node.js. I am also passionate about data analysis, using
          it to drive informed decision-making.
        </p>
      </div>
    </div>
  );
}

export default About;
