import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FiExternalLink } from "react-icons/fi";

const projectsData = [
  {
    id: 1,
    type: "Full-Stack",
    title: "Ecommerce Shopping Site",
    technologies: ["Node", "Express", "MongoDB", "React"],
    image: "/images/Fashion_wear.png",
    link: "https://github.com/Sandip-3/Mern-Ecommerce",
  },
  {
    id: 2,
    type: "PHP",
    title: "Utility Provider App",
    technologies: ["PHP", "MySQL", "JavaScript", "CSS"],
    image: "/images/Kaamdaar.png",
    link: "https://github.com/Sandip-3/Kaamdaar-Web-Project",
  },
  {
    id: 3,
    type: "Full-Stack",
    title: "MERN-Goal Setter",
    technologies: ["Node", "Express", "MongoDB", "React"],
    image:
      "https://github.com/Sandip-3/Define-Goal/blob/main/ScreenShot/Screenshot%202023-12-28%20at%208.32.56%E2%80%AFPM.png?raw=true",
    link: "https://github.com/Sandip-3/Define-Goal",
  },
  {
    id: 4,
    type: "ML AI",
    title: "Image Captioning",
    technologies: ["Python", "Tensorflow", "Keras"],
    image: "/images/image_cap.png",
    link: "https://www.kaggle.com/code/antares2057/college-project",
  },
  {
    id: 5,
    type: "Full Stack",
    title: "Secondhand Market",
    technologies: ["Node", "Express", "React", "Shadcn"],
    image: "/images/second_market.png",
    link: "https://www.linkedin.com/posts/sandip-poudel-77a2a829a_i-have-developed-a-comprehensive-second-hand-activity-7268127565410693120-2dMy?utm_source=share&utm_medium=member_desktop",
  },
  {
    id: 6,
    type: "Full Stack",
    title: "Task Management",
    technologies: ["Node", "Express", "React", "Shadcn"],
    image: "/images/Task.png",
    link: "https://github.com/Sandip-3/modern_todo",
  },
  {
    id: 7,
    type: "Clone",
    title: "KFC Nepal",
    technologies: ["Tailwind", "React"],
    image: "/images/kfc.png",
    link: "https://github.com/Sandip-3/KFC_Clone_React",
  },
  {
    id: 8,
    type: "Clone",
    title: "YTS Movie",
    technologies: ["Tailwind", "React", "YTS API"],
    image: "/images/yts.png",
    link: "https://github.com/Sandip-3/yts_clone",
  },
];

function Projects({ isDarkMode }) {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="text-center mt-12 mb-12 px-4 lg:px-8">
      <div className="mb-12 border-b border-gray-300 pb-4">
        <h1 className="font-serif text-3xl lg:text-4xl text-slate-600 uppercase">
          Recent Projects
        </h1>
      </div>
      <Slider {...sliderSettings} className="mx-auto max-w-[1400px]">
        {projectsData.map((project) => (
          <div key={project.id} className="p-4 h-fit">
            <div className="relative group">
              <p className="z-10 absolute top-[-10px] left-[-5px] py-1 px-5 text-sm text-white font-serif bg-orange-500 rounded-full">
                {project.type}
              </p>
              <div className="relative overflow-hidden shadow-lg flex flex-col rounded-lg bg-white">
                <img
                  className="rounded-lg object-fill h-60 md:h-72 lg:h-80 transition-transform transform group-hover:scale-105 duration-700"
                  src={project.image}
                  alt={project.title}
                />
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="absolute inset-0 bg-black bg-opacity-40 opacity-0 flex items-center justify-center text-white text-3xl transition-opacity duration-300 group-hover:opacity-100"
                >
                  <FiExternalLink />
                </a>
                <div
                  className={`absolute bottom-0 left-0 right-0 p-4 transition-transform transform group-hover:translate-y-0 translate-y-full ${
                    isDarkMode ? "bg-[#2e2e2e]" : "bg-white"
                  }`}
                >
                  <h2 className="text-lg lg:text-xl font-serif uppercase mb-2 text-center">
                    {project.title}
                  </h2>
                  <div className="flex flex-wrap justify-center gap-2">
                    {project.technologies.map((tech, index) => (
                      <span
                        key={index}
                        className="text-xs md:text-sm bg-slate-700 text-white px-3 py-1 rounded-full"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Projects;
